import React from 'react';
import { formatDateNew } from '../utils/helpers';

const DeliveryDate = ({ dontShow }) => {
  var myNewDate = new Date();
  myNewDate.setDate(myNewDate.getDate() + 72);
  const formatedDate = formatDateNew(new Date(myNewDate), 'MMMM d, yyyy', true);
  return (
    <p className='delivery-date'>
      {!dontShow && 'Estimated Ship Date:'} {formatedDate}{' '}
    </p>
  );
};

export default DeliveryDate;
